<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title> Edit Riwayat Pendidikan / Sekolah </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-6">
          <!-- Tingkat Pendidikan -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tingkat Pendidikan</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="editedItem.pendidikan.tk.id"
                  hint="Pendidikan Terakhir"
                  persistent-hint
                  :items="refPendidikan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                  return-object
                  @change="changePT"
                ></v-select>
              </v-col>
              <v-col cols="12" md="8">
                <v-autocomplete
                  v-model="editedItem.pendidikan.id"
                  hint="Nama Pendidikan"
                  persistent-hint
                  :items="refSubPendidikan"
                  item-value="id"
                  item-text="nama"
                  dense
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nama / Tempat Sekolah / PT -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama / Tempat Sekolah</span>
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  v-model="editedItem.namaSekolah"
                  hint="Nama"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.lokasiSekolah"
                  hint="Tempat"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Nomor/ Tanggal Ijazah -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nomor/ Tanggal Ijazah</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.nomorIjasah"
                  hint="Nomor"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="lulusDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      hint="Tanggal"
                      persistent-hint
                      v-model="editedItem.tglLulus"
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.tglLulus"
                    @input="lulusDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.tahunLulus"
                  hint="Tahun Lulus"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Kepsek / Ketua / Rektor -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Kepsek / Ketua / Rektor</span>
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  v-model="editedItem.namaKepalaSekolah"
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <!-- Gelar -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Gelar</span>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.pendidikan.gelar.depan"
                  hint="Depan"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="editedItem.pendidikan.gelar.belakang"
                  hint="Belakang"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";
import getRef from "@/helper/getReferensi.js";

import refreshView from "@/store/asn/rwpendidikan/viewRwpendidikan";
import modal_editState from "@/store/asn/rwpendidikan/modal_edit";

export default {
  name: "rwpendidikanModalEdit",

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit;
      },

      set(value) {
        modal_editState.commit("toggleModal", value);
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwpendidikan;
      },

      set(value) {
        console.log(value);
        // modal_editState.commit("editModal", value);
      },
    },
  },

  watch: {
    editedItem() {
      this.changePT(this.editedItem.pendidikan.tk);
    },

    async ModalEdit(value) {
      if (value) {
        this.refPendidikan = await getRef.Pendidikan(this.token);
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    btnLoading: true,
    rwpendidikan: {},
    get_nip: "",

    // Date Picker
    tglLulus: new Date().toISOString().substr(0, 10),
    lulusDate: false,

    refPendidikan: [],
    refSubPendidikan: [],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id;
  },

  methods: {
    reset() {
      this.rwpendidikan = {
        id: "",
        pendidikan: {
          id: "",
          tk: {
            id: 0,
            nama: "",
          },
          gelar: {
            depan: null,
            belakang: null,
          },
        },
        namaSekolah: "",
        lokasiSekolah: "",
        tahunLulus: "",
        nomorIjasah: "",
        namaKepalaSekolah: "",
        tglLulus: "",
      };
    },

    async edit() {
      this.btnLoading = false;

      let data = {
        id: this.editedItem.id,
        pendidikan: {
          id: this.editedItem.pendidikan.id,
          gelar: {
            depan: this.editedItem.pendidikan.gelar.depan,
            belakang: this.editedItem.pendidikan.gelar.belakang,
          },
        },
        namaSekolah: this.editedItem.namaSekolah,
        lokasiSekolah: this.editedItem.lokasiSekolah,
        tahunLulus: this.editedItem.tahunLulus,
        nomorIjasah: this.editedItem.nomorIjasah,
        namaKepalaSekolah: this.editedItem.namaKepalaSekolah,
        tglLulus: this.editedItem.tglLulus,
      };

      const url = process.env.VUE_APP_ASN + "pendidikan/nip/" + this.get_nip;

      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.btnLoading = true;
          if (response.data.success) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("berhasilAlert", true);
            refreshView.commit("gagalAlert", false);
            refreshView.commit("success", response.data.success);
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", response.data.success);
          }
          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.btnLoading = true;
          } else {
            refreshView.commit("refreshData", true);
            refreshView.commit("alert", error.response.data.message);
            refreshView.commit("gagalAlert", true);
            refreshView.commit("berhasilAlert", false);
            refreshView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.btnLoading = true;
          }
        });
    },

    async changePT(value) {
      const token = await Cookie.get("token");
      const response = await getRef.subPendidikan(token, value.id);
      this.refSubPendidikan = response;
    },

    async closeModal() {
      await this.reset();
      await modal_editState.commit(
        "editModal",
        Object.assign({}, this.rwpendidikan)
      );
      await refreshView.commit("refreshData", true);
      this.ModalEdit = false;
    },
  },
};
</script>
