import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,
        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },
        rwpendidikan: {
            id: '',
            pendidikan: {
                id: '',
                tk: {
                    id: 5,
                    nama: ''
                },
                gelar: {
                    depan: null,
                    belakang: null
                }
            },
            namaSekolah: '',
            lokasiSekolah: '',
            tahunLulus: '',
            nomorIjasah: '',
            namaKepalaSekolah: '',
            tglLulus: ''
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },
        tosimasnModal(state, value) {
            state.rwpendidikan = value
        },
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})
