import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalHapus: false,
    rwpendidikan: {
      id: '',
      pendidikan: {
        id: '',
        gelar: {
          depan: null,
          belakang: null
        }
      },
      namaSekolah: '',
      lokasiSekolah: '',
      tahunLulus: '',
      nomorIjasah: '',
      namaKepalaSekolah: '',
      tglLulus: ''
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalHapus = value
    },
    hapusModal(state, value) {
      state.rwpendidikan = value
    },
  }
})
